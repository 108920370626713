@font-face {
    font-family: 'Inter Bold';
    src: url('fonts/Inter-Bold.ttf');
}

@font-face {
    font-family: 'Inter Regular';
    src: url('fonts/Inter-Regular.ttf');
}
@font-face {
    font-family: 'Inter Medium';
    src: url('fonts/Inter-Medium.ttf');
}

@font-face {
    font-family: 'Poppins Medium';
    src: url('fonts/Poppins-Medium.ttf');
}

.green-text{
    background: linear-gradient(to right, #16FF20, #009206);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.orange-text{
    background: linear-gradient(to right, #FFA500, #FFFF00);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.purple-text{
    background: linear-gradient(to right, #800080, #8B008B);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.blue-text{
    background: linear-gradient(to right, #0000FF, #1E90FF);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.orangepurple-text{
    background: linear-gradient(to right, #FFA500, #800080);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

*{
    outline:none
}

html{
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
}

body{
    margin: 0;
}

.row{
    margin: 0;
}

.page-container{
    padding-left:5rem;
    padding-right:5rem
}

.navbar-nav .nav-item .nav-link {
    color: white; /* Replace with your desired hover color */
    border-radius: 5px;
    font-family: Inter Medium;

}

.navbar-nav .nav-item .nav-link:hover {
    color: #430BFF; /* Replace with your desired hover color */
    transition: 0.5s;
}


#top-section{
    background-image: url('/public/assets/Purple_Background.png');
    background-repeat: no-repeat;
    background-size: cover;
}
#bottom-section {
    background-image: url('/public/assets/Purple_Background-Bot.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

h1{
    font-family: Inter Bold;
}

h2{
    font-family: Inter Bold;
}

h5{
    font-family: Poppins Medium;
}

p{
    font-family: Inter Medium;
}

li{
    font-family: Inter Medium;
}
.purple-button {
    background: linear-gradient(95deg, #430BFF 0%, #2B00BC 100%);
    font-family: 'Inter Bold', sans-serif;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    white-space: nowrap
}

.purple-button:hover {
    background: #23098C;
}

#footer-item{
    color: #ffffff;
    font-family: Inter Medium !important;
}
#footer-item:hover{
    color: #430BFF;
}

.legal-white-card{
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px;
    font-family: Inter Medium;
    color : '#170D41';
    text-align: center;
    cursor: pointer;
}

.legal-white-card:hover{
    background-color: #430BFF;
    color: white !important;
    transition: 0.1s ease-in-out;

}
.legal-white-card:hover p{
    color: white !important;

}



.form-login{
    width: 40%;
}

.white-card{
    background-color: #ffffff;
    border-radius: 15px;
}

.blog-card{
    background-color:#000000;
    border-radius:20px;
    transition: 0.2s;
    cursor: pointer;
}

.blog-card:hover{
    background-color:#EAEAEA;
    
}
.blog-card:hover p{
    color:#170D41 !important ;   
}
.blog-card:hover big{
    color:#170D41 !important ;   
}
.blog-card:hover small{
    color:#170D41 !important ;
}
.blog-card:hover h6{
    color:#170D41 !important ;
}

.public-DraftEditor-content img{
    max-width: 100% !important;
}


/* Small devices (phones, 767 and below)*/
@media only screen and (max-width: 767px) { 
    .features-section{
        max-height: 200vh !important;
        padding-bottom: 75px;
    }
    .max-width{
        max-width: 400px;
        margin: auto;
    }
    .mobile-display{
        display: block !important;
    }
    .desktop-display{
        display: none !important;
    }
    .page-container{
        padding-left:1rem;
        padding-right:1rem
    }
    
    #legal-content-width{
        width: 100% !important;
    }
    #top-illustration{
        width: 400px !important;
    }
    .gif-asset{
        width: 400px !important;
        height: 500px !important;
        object-fit: cover;
        margin-top: 75px;
    }
    .form-login{
        width: 100%;
    }
    h6{
        font-size: 12px !important;
    }

}

/* @media (min-width: 768px) and (orientation:portrait) and (max-width: 991px)  {
    #navbar-logo{
        width: 150px;
        height: auto;
    }
    .mobile-display{
        display: block !important;
    }
    .desktop-display{
        display: none !important;
    }
    #legal-content-width{
        width: 100% !important;
    }
    #top-illustration{
        width: 70% !important;
    }
    .gif-asset{
        width: 50% !important;
        height: 60vh !important;
        object-fit: cover;
        margin-top: 75px;
    }
    .features-section{
        max-height: 200vh !important;
        padding-bottom: 75px;
    }

    h1{
        font-size: 60px;
    }

    p{
        font-size: 30px;
    }
    h5{
        font-size: 30px;
    }
    a{
        font-size: 30px;
    }
    li{
        font-size: 30px;
    }
    .form-login{
        width: 100%;
    }
    .max-width{
        max-width: 2000px;
        margin: auto;
    }
    h6{
        font-size: 18px !important;
    }
} */
@media (min-width: 768px)and (max-width: 991px) {
    #navbar-logo{
        width: 150px;
        height: auto;
    }
    .mobile-display{
        display: block !important;
    }
    .desktop-display{
        display: none !important;
    }
    #legal-content-width{
        width: 100% !important;
    }
    #top-illustration{
        width: 350px !important;
        padding-bottom: 30px;
    }
    .gif-asset{
        width: 350px !important;
        height: 500px !important;
        object-fit: cover;
        margin-top: 75px;
    }
    .features-section{
        max-height: 200vh !important;
        padding-bottom: 75px;
    }

    h1{
        font-size: 40px;
    }
    h2{
        font-size: 30px;
    }

    p{
        font-size: 20px;
    }
    h5{
        font-size: 25px;
    }
    a{
        font-size: 20px;
    }
    li{
        font-size: 20px;
    }
    .form-login{
        width: 100%;
    }
    .max-width{
        max-width: 800px;
        margin: auto;
    }
    h6{
        font-size: 18px !important;
    }
}


/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1099) {
    
    #navbar-logo{
        width: 100px;
        height: auto;
    }
    .page-container{
        padding-left:7%;
        padding-right:7%
    }
    #top-illustration{
        width: 300px !important;
    }
    .max-width{
        max-width: 1000px;
        margin: auto;
    }
    .gif-asset{
        width: 70% !important;
    }
    h1{
        font-size: 32px;
    }
    h2{
        font-size: 25px;
    }

    p{
        font-size: 15px;
    }
    li{
        font-size: 15px;
    }
    h5{
        font-size: 18px;
    }
    a{
        font-size: 15px;
    }
    h6{
        font-size: 10px !important;
    }
    
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1100px) and (max-width: 1449px) {
    .page-container{
        padding-left:5%;
        padding-right:5%
    }
    #top-illustration{
        width:400px !important;
    }
    .gif-asset{
        width: 450px !important;
    }
    #navbar-logo{
        width: 120px;
        height: auto;
    }
    .max-width{
        max-width: 1300px;
        margin: auto;
    }

    .features-section{
        max-height: 120vh !important;
    }

    h1{
        font-size: 40px;
    }
    h2{
        font-size: 35px;
    }
    h6{
        font-size: 17px !important;
        line-height: 20px !important;
    }

    p{
        font-size:18px;
    }
    li{
        font-size:18px;
    }
    h5{
        font-size: 25px;
    }
    a{
        font-size:18px;
    }

}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1450px) and (max-width: 1749px) {
    .page-container{
        padding-left:5%;
        padding-right:5%
    }
    #top-illustration{
        width:450px !important;
    }
    .gif-asset{
        width: 480px !important;
    }
    #navbar-logo{
        width: 150px;
        height: auto;
    }
    .max-width{
        max-width: 1400px;
        margin: auto;
    }

    h1{
        font-size: 45px;
    }
    h2{
        font-size: 37px;
    }
    h6{
        font-size: 17px !important;
        line-height: 20px !important;
    }

    p{
        font-size: 20px;
    }
    li{
        font-size: 20px;
    }
    h5{
        font-size: 28px;
    }
    a{
        font-size: 20px;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1750px) and (max-width: 2499px) {
    
    .page-container{
        padding-left:5%;
        padding-right:5%
    }
    #top-illustration{
        width: 500px !important;
    }
    .gif-asset{
        width: 80% !important;
    }
    #navbar-logo{
        width: 150px;
        height: auto;
    }
    #footer-icon{
        width:35px !important; 
        height: 35px !important;
    }
    .max-width{
        max-width: 1700px;
        margin: auto;
    }

    h1{
        font-size: 60px;
    }
    h2{
        font-size: 50px;
    }
    h6{
        font-size: 20px !important;
        line-height: 25px !important;
    }

    p{
        font-size: 25px;
    }
    li{
        font-size: 25px;
    }
    h5{
        font-size: 30px;
    }
    a{
        font-size: 25px;
    }
    #blog-cover{
        max-height: 500px !important;
    }

    
    

}
/* Extra large devices (large desktops, 2200px and up)*/
@media (min-width: 2500px) and (max-width: 2999px) {
    
    .page-container{
        padding-left:5%;
        padding-right:5%
    }
    #top-illustration{
        width: 550px !important;
    }
    .gif-asset{
        width: 80% !important;
    }
    #navbar-logo{
        width: 200px;
        height: auto;
    }
    #footer-icon{
        width:35px !important; 
        height: 35px !important;
    }
    .max-width{
        max-width: 2000px;
        margin: auto;
    }

    h1{
        font-size: 75px;
    }
    h2{
        font-size: 60px;
    }
    h6{
        font-size: 20px !important;
        line-height: 25px !important;
    }

    p{
        font-size: 30px;
    }
    li{
        font-size: 30px;
    }
    h5{
        font-size: 35px;
    }
    a{
        font-size: 30px;
    }
    #blog-cover{
        max-height: 500px !important;
    }

    
    

}
@media (min-width: 3000px) {
    
    
    .page-container{
        padding-left:5%;
        padding-right:5%
    }
    #top-illustration{
        width: 550px !important;
    }
    .gif-asset{
        width: 80% !important;
    }
    #navbar-logo{
        width: 200px;
        height: auto;
    }
    #footer-icon{
        width:35px !important; 
        height: 35px !important;
    }
    .max-width{
        max-width: 2000px;
        margin: auto;
    }

    h1{
        font-size: 75px;
    }
    h2{
        font-size: 60px;
    }
    h6{
        font-size: 20px !important;
        line-height: 25px !important;
    }

    p{
        font-size: 30px;
    }
    li{
        font-size: 30px;
    }
    h5{
        font-size: 35px;
    }
    a{
        font-size: 30px;
    }

    #blog-cover{
        max-height: 500px !important;
    }

    
    

}



/* start of navbar */
.nav-colored { 
    -webkit-box-shadow: 0 10px 6px -6px  #e5e5e5;
    -moz-box-shadow: 0 10px 6px -6px  #e5e5e5;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.25);      
    transition: 0.3s ease-in-out;
    background-color:white ;
}

.nav-transparent { 
    background-color:transparent;
    transition: 0.3s ease-in-out;
}

